import { keysToSnakeCaseDeep } from '@agro1desenvolvimento/utils-js';
import { APIAgroanalisaService } from '.';

class IndexSummaries {
  private baseUrl = 'v1/index_summaries';

  async generateCsv(seasonUuid?: string, cropUuid?: string) {
    const response = await APIAgroanalisaService.get<Blob>(`${this.baseUrl}/csv`, {
      params: keysToSnakeCaseDeep({
        seasonUuid,
        cropUuid,
      }),
      responseType: 'blob',
    });
    return new Blob([response.data], { type: 'text/csv' });
  }
}

export default new IndexSummaries();
