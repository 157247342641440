import { keysToSnakeCase } from '@agro1desenvolvimento/utils-js';
import { APIAgroanalisaService } from '.';
import { Customer } from '../@types/customer';

class Customers {
  private endpoint = 'v1/customers';

  async findOneById(id: string) {
    const { data } = await APIAgroanalisaService.get<Customer>(`${this.endpoint}/${id}`);
    return data;
  }

  async find(q: string) {
    const { data } = await APIAgroanalisaService.get<Customer[]>(this.endpoint, { params: { q } });
    return data;
  }

  async create(customerData: CustomerData) {
    const customer = keysToSnakeCase(customerData);

    const { data } = await APIAgroanalisaService.post<Customer>(this.endpoint, { customer });
    return data;
  }

  async update(id: string, customerData: CustomerData) {
    const customer = keysToSnakeCase(customerData);

    const { data } = await APIAgroanalisaService.patch<Customer>(`${this.endpoint}/${id}`,
      { customer });
    return data;
  }

  remove(id: string) {
    return APIAgroanalisaService.delete(`${this.endpoint}/${id}`);
  }
}

export interface CustomerData {
  name: string;
  email: string;
  regionUuid: string;
  cityUuid: string;
}

export default new Customers();
